<template>
  <FairFieldEditor title="Häufig gestellte Fragen (FAQ)" :index="16" :is-modal-open.sync="isModalOpen"
    :loading="isLoading" @save="handleSave" :saveDisabled="!computedIsValid" buttonSaveText="Änderungen speichern"
    @toggle="toggleContent" :usePageStore="true" @update:isModalOpen="updateFormFields" :enableAdminEdit="false">
    <div class="d-flex flex-column gap-3 w-100">
      <FaqQuestionContainer v-for="(faq, index) of computedSelectedFaqsRendered" :key="index" :faqId="faq.FaqID"
        :title="rewrittenQuestion(faq)" :index="index + 1" :isOpen="computedCurrentFaqIsOpen(index + 1)"
        @toggle="toggleFaq" :showSelect="false">
        <div v-html="rewrittenAnswer(faq)" />
      </FaqQuestionContainer>
    </div>

    <template v-slot:editButtonCaption>
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-pencil"></i>
        <span>Auswahl ändern</span>
      </div>
    </template>
    <template v-slot:preview>
      <img class="img-fluid" src="@/assets/images/new-fair/5_FAQ.png" />
    </template>

    <template v-slot:edit>
      <p>
        Die häufig gestellten Fragen werden ganz unten am Messestand angezeigt
        und sollen mögliche Einwände von zögernden Interessenten entkräften.
      </p>

      <hr />

      <div class="d-flex flex-column gap-3">
        <transition-group tag="div" name="faq-move" class="d-flex flex-column gap-3">
          <FaqQuestionEditContainer v-for="(faq, index) in formFields.faqs" :key="faq.__localId" :faq="faq"
            :index="index" :isFirst="computedIsFirstFaq(index)" :isLast="computedIsLastFaq(index)"
            @sortOrderUp="handleSortOrderUp" @sortOrderDown="handleSortOrderDown" @removeFaq="handleRemoveFaq" />
        </transition-group>

        <div class="quote-box">
          <div class="next-steps-headline">Eine neue Frage hinzufügen</div>
          <div class="d-flex gap-2 w-100 new-question-select-container">
            <b-form-select class="new-question-select flex-grow-1" v-model="newFaqOptionSelected"
              :options="computedNewFaqOptions"></b-form-select>
            <button class="btn btn-secondary flex-shrink-0" @click="addNewSelectedFaq"
              :disabled="newFaqOptionSelected === null">
              <i class="fa fa-plus"></i>
              <span>Neue Frage hinzufügen</span>
            </button>
          </div>
        </div>

      </div>

      <hr />

      <div class="d-flex flex-column w-100 gap-3">
        <div class="d-flex flex-column gap-1">
          <span class="fw-bold">Anforderungen:</span>
          <ul class="special">
            <li :class="{
              success: formFields.faqs.length >= 4,
              error: formFields.faqs.length < 4
            }">Mindestens 4 Fragen / Antworten müssen hinterlegt sein</li>
            <li :class="{
              success: formFields.faqs.length <= 9,
              error: formFields.faqs.length > 9
            }">Maximal 9 Fragen / Antworten dürfen hinterlegt werden</li>
            <li :class="{
              success: computedAllTextFieldsAreFilled,
              error: !computedAllTextFieldsAreFilled
            }">Alle Textfelder müssen ausgefüllt sein</li>
            <li>
              Die Fragen / Antworten müssen zu Ihrem Geschäftskonzept passen
              (Quereinstieg / Konditionen / angebotene Unterstützungen etc.)
            </li>
            <li>
              Eigene, individuelle Fragen / Antworten sind aktuell noch nicht
              möglich
            </li>
          </ul>
        </div>
      </div>
    </template>
  </FairFieldEditor>
</template>

<script>
import FairFieldEditor from '@/components/FairFieldEditor.vue'
import FaqQuestionContainer from '@/components/FaqQuestionContainer.vue'
import FaqQuestionEditContainer from '@/components/fair/FaqQuestionEditContainer.vue'
import { useBrandStore } from '@/stores/brandStore'

export default {
  name: 'FairFormFAQ',
  components: {
    FairFieldEditor,
    FaqQuestionContainer,
    FaqQuestionEditContainer
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      openedFaqIndexes: [1],
      openedFaqIndexesInsideModal: [],
      openedFaqIndexesInsideModalBrand: [],
      formFields: {
        faqs: []
      },
      newFaqLocalIdCounter: 0,
      newFaqOptionSelected: -1
    }
  },
  methods: {
    toggleEdit () {
      this.isEditing = !this.isEditing
    },
    toggleContent () {
      this.isOpen = !this.isOpen
    },
    updateFormFields () {
      // Create a fresh clone of each FAQ to avoid mutating the original data and use newFaqLocalIdCounter and __localId to identify new FAQs
      this.formFields.faqs = this.computedSelectedFaqsRendered.map(faq => ({ ...faq }))
        .map(faq => {
          this.newFaqLocalIdCounter += 1
          return {
            ...faq,
            __localId: this.newFaqLocalIdCounter
          }
        })

      // Set SortOrder based on their current position
      this.formFields.faqs.forEach((faq, index) => {
        this.$set(faq, 'SortOrder', index)
      })
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Änderungen erfolgreich gespeichert.')
        this.isModalOpen = false
        this.formFields.value = ''
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error(
          'Beim Speichern deiner Änderungen ist ein Fehler aufgetreten.'
        )
      } finally {
        this.isLoading = false
      }
    },
    rewrittenQuestion (question) {
      return String(question.Question ?? '')
        .replaceAll('{{EXPANSIONSFORM}}', this.computedExpansionFormCaption)
        .replaceAll('{{MARKE}}', this.currentBrand.Name)
    },
    rewrittenAnswer (answer) {
      return String(answer.Answer ?? '')
        .replaceAll('{{EXPANSIONSFORM}}', this.computedExpansionFormCaption)
        .replaceAll('{{MARKE}}', this.currentBrand.Name)
        .replaceAll('\n', '<br>')
    },
    toggleFaq (index) {
      if (this.openedFaqIndexes.includes(index)) {
        this.openedFaqIndexes = this.openedFaqIndexes.filter(
          (i) => i !== index
        )
      } else {
        this.openedFaqIndexes.push(index)
      }
    },
    toggleFaqInsideModal (index) {
      if (this.openedFaqIndexesInsideModal.includes(index)) {
        this.openedFaqIndexesInsideModal =
          this.openedFaqIndexesInsideModal.filter((i) => i !== index)
      } else {
        this.openedFaqIndexesInsideModal.push(index)
      }
    },
    handleSortOrderUp (__localId) {
      const index = this.formFields.faqs.findIndex((faq) => faq.__localId === __localId)
      if (index > 0) {
        const currentFaq = this.formFields.faqs[index]
        const previousFaq = this.formFields.faqs[index - 1]

        // Swap SortOrder values
        const tempSortOrder = currentFaq.SortOrder
        this.$set(currentFaq, 'SortOrder', previousFaq.SortOrder)
        this.$set(previousFaq, 'SortOrder', tempSortOrder)

        // Re-sort the FAQs
        this.formFields.faqs.sort((a, b) => a.SortOrder - b.SortOrder)
      }
    },
    handleSortOrderDown (__localId) {
      const index = this.formFields.faqs.findIndex((faq) => faq.__localId === __localId)
      if (index < this.formFields.faqs.length - 1) {
        const currentFaq = this.formFields.faqs[index]
        const nextFaq = this.formFields.faqs[index + 1]

        // Swap SortOrder values
        const tempSortOrder = currentFaq.SortOrder
        this.$set(currentFaq, 'SortOrder', nextFaq.SortOrder)
        this.$set(nextFaq, 'SortOrder', tempSortOrder)

        // Re-sort the FAQs
        this.formFields.faqs.sort((a, b) => a.SortOrder - b.SortOrder)
      }
    },
    handleRemoveFaq (__localId) {
      this.formFields.faqs = this.formFields.faqs.filter(
        (faq) => faq.__localId !== __localId
      )
    },
    addNewSelectedFaq () {
      const brandStore = useBrandStore()
      if (this.newFaqOptionSelected === null) return

      if (this.newFaqOptionSelected === -1) {
        this.newFaqLocalIdCounter += 1
        this.formFields.faqs.push({
          FaqID: -1,
          BrandID: this.currentBrand.BrandID,
          ExternalID: 0,
          Question: '',
          Answer: '',
          SortOrder: this.formFields.faqs.length,
          __localId: this.newFaqLocalIdCounter
        })
      } else {
        const selectedFaq = brandStore.getDefaultFaqs.find(
          (faq) => faq.FaqID === this.newFaqOptionSelected
        )
        if (selectedFaq) {
          this.newFaqLocalIdCounter += 1
          this.formFields.faqs.push({
            FaqID: selectedFaq.FaqID,
            BrandID: this.currentBrand.BrandID,
            ExternalID: null,
            Question: this.rewrittenQuestion(selectedFaq),
            Answer: this.rewrittenAnswer(selectedFaq),
            SortOrder: this.formFields.faqs.length,
            __localId: this.newFaqLocalIdCounter
          })
        }
      }

      this.newFaqOptionSelected = -1
    },
    async submitForm () {
      const brandStore = useBrandStore()
      await brandStore.updateBrandFaqs(this.formFields.faqs)
    }
  },
  computed: {
    computedIsValid () {
      return (
        this.formFields.faqs.length >= 4 &&
        this.formFields.faqs.length <= 9 &&
        this.computedAllTextFieldsAreFilled
      )
    },
    computedNewFaqOptions () {
      const brandStore = useBrandStore()
      return [
        { value: -1, text: 'Neue Frage / Antwort ohne Vorlage erstellen' },
        ...brandStore.getDefaultFaqs
          .filter((faq) => {
            return faq.ExternalID !== 13
          })
          .map((faq) => ({
            value: faq.FaqID,
            text: this.rewrittenQuestion(faq)
          }))
      ]
    },
    computedAllTextFieldsAreFilled () {
      console.log('this.formFields.faqs', this.formFields.faqs)
      return this.formFields.faqs.every((faq) => faq.Question && faq.Answer && String(faq.Answer).length > 0)
    },
    computedExpansionFormCaption () {
      if (this.currentBrand) {
        if (this.currentBrand.ConceptExpansionFormFranchise) return 'Franchise'
        if (this.currentBrand.ConceptExpansionFormLicense) return 'Lizenz'
      }
      return ''
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    },
    computedSelectedFaqs () {
      const brandStore = useBrandStore()
      return [...brandStore.getBrandFaqs]
        .filter((faq) => faq.ExternalID !== 13)
        .sort((a, b) => a.SortOrder - b.SortOrder)
    },
    computedSelectedFaqsRendered () {
      const selectedFaqs = [
        ...this.computedSelectedFaqs
      ]
      if (this.computedNextStepsFaq) {
        return [
          ...selectedFaqs,
          this.computedNextStepsFaq
        ]
      }
      return selectedFaqs
    },
    computedNextStepsFaq () {
      const brandStore = useBrandStore()
      const existingFaq = brandStore.getBrandFaqs.find((faq) => faq.ExternalID === 13)

      if (existingFaq) {
        return existingFaq
      }

      return {
        FaqID: -1,
        BrandID: this.currentBrand.BrandID,
        ExternalID: 13,
        Question: 'Ich bin startklar, mein eigenes Unternehmen zu gründen. Wie geht\'s weiter?',
        Answer: [
          'Wir kontaktieren dich in wenigen Tagen. Vergiss nicht, dein Handy in der Nähe zu behalten. ;-)',
          'Mach dir keine Sorgen, du wirst durch den gesamten Gründungsprozess begleitet. Unser Team steht dir zur Seite, um sicherzustellen, dass du den bestmöglichen Start hast.'
        ].join('\n'),
        SortOrder: brandStore.getBrandFaqs.length
      }
    },
    computedCurrentFaqIsOpen () {
      return function (tab) {
        return this.openedFaqIndexes.includes(tab)
      }
    },
    computedCurrentFaqIsOpenInsideModal () {
      return function (tab) {
        return this.openedFaqIndexesInsideModal.includes(tab)
      }
    },
    computedCurrentBrandFaqIsOpenInsideModal () {
      return function (tab) {
        return this.openedFaqIndexesInsideModalBrand.includes(tab)
      }
    },
    computedIsFirstFaq () {
      return function (index) {
        return index === 0
      }
    },
    computedIsLastFaq () {
      return function (index) {
        return index + 1 === this.formFields.faqs.length
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rps-entry-row {
  display: flex;
  background-color: #eff1f4;
  width: 100%;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  .type {
    width: 150px;
  }

  .place {
    font-weight: 700;
  }
}

.special-headline {
  color: white;
  display: flex;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;

  &.bg-green {
    background: #91c124;
  }

  &.bg-orange {
    background: #d97706;
  }
}

.faq-move-enter-active,
.faq-move-leave-active {
  transition: all 0.3s ease;
}

/* If you want a fade-in effect on enter */
.faq-move-enter {
  opacity: 0;
}

.faq-move-enter-to {
  opacity: 1;
}

/* If you want a fade-out effect on leave */
.faq-move-leave-to {
  opacity: 0;
}

/* The 'move' transition is for reordering movements */
.faq-move-move {
  transition: transform 0.3s ease;
}

.new-question-select {
  min-width: 0;
  border-radius: 4px;
  padding: 12px 8px;
}

.new-question-select-container button {
  white-space: nowrap;
  /* Verhindert, dass der Button-Inhalt umbricht */
}
</style>
